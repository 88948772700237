<template>
    <div>
        <vue-slider ref="slider" v-model="parameterValue" class="px-0" :dot-size="16" :height="6" :min="min" :max="max" :lazy="true"
                    :tooltip="isMobileView ? 'focus' : 'none'" :marks="false" :interval="interval ? interval : 1" :tooltip-formatter="getLabelFormattedValue"
                    :process-style="sliderProcessStyle" :tooltip-style="sliderProcessStyle"
                    @drag-start="draggingSlider = true" @drag-end="draggingSlider = false">

            <template v-slot:dot="{ value, focus }">
                <div :class="[(draggingSlider && isMobileView) ? 'custom-dot-dragged' : 'custom-dot', { focus }]">
                    <span class="custom-dot-text">
                        {{(draggingSlider && isMobileView) ? '' : getLabelFormattedValue(value)}}
                    </span>
                </div>
            </template>
        </vue-slider>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import vueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import {isMobile} from "@/utilities/mobile.navigation.utility";
    import {getSliderDotValue} from "@/utilities/parameter.utility";

    export default {
        name: "Slider",

        components: {
            vueSlider
        },

        props: [
            'parameterKey',
            'value',
            'min',
            'max',
            'interval'
        ],

        data() {
            return {
                draggingSlider: false,
                sliderProcessStyle: {backgroundColor: 'var(--primary)'}
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            isMobileView() {
                return isMobile();
            },

            parameterValue: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value)
                }
            }
        },

        methods: {
            getLabelFormattedValue(val) {
                return getSliderDotValue(this.parameterKey, val, this.min, this.max, this.parameters);
            }
        }
    }
</script>

<style scoped>
</style>