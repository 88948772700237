<template>
    <label :id="'label_' + parameterKey" style="text-align: right;">
        <strong>
            {{ this.labelTextFormatter(parameterKey, parameterValue) }}
        </strong>
    </label>
</template>

<script>
    import {valueFormatter} from "@/utilities/parameter.utility";

    export default {
        name: "ReadOnly",

        props: [
            'parameterKey',
            'parameterValue'
        ],

        methods: {
            labelTextFormatter(key, value) {
                return valueFormatter(key, value);
            }
        }
    }
</script>

<style scoped>

</style>