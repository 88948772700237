<template>
    <div>
        <vue-slider ref="slider" class="px-0 pb-4" v-model="parameterValue" :dot-size="16" :height="6" :min="min" :max="max" :lazy="false"
                    :disabled="pending !== false" :marks="false" :process-style="sliderProcessStyle" :tooltip-style="sliderProcessStyle"
                    :tooltip="isMobileView ? 'focus' : 'none'" @drag-start="dragStart()" @change="variantValueChange()" @drag-end="dragEnd()">

            <template v-slot:dot="{ value, focus }">
                <div :class="[(draggingSlider && isMobileView) ? 'custom-dot-dragged' : 'custom-dot', { focus }]">
                    <span class="custom-dot-text">
                        {{(draggingSlider && isMobileView) ? '' : getLabelFormattedValue(value)}}
                    </span>
                </div>
            </template>
        </vue-slider>

        <b-carousel v-if="$OEM === 'pleion'" no-animation id="variant-carousel" class="slide" background="#ffffff" :interval="0" img-width="370"
                    img-height="186" v-model="variantSlide">
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant1.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant2.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant3.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant4.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant5.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant6.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant7.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant8.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant9.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant10.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant11.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/pleion-v1/variant12.svg"></b-carousel-slide>
        </b-carousel>
        <b-carousel v-else no-animation id="variant-carousel" class="slide" background="#ffffff" :interval="0" img-width="370"
                    img-height="186" v-model="variantSlide">
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant1.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant2.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant3.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant4.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant5.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant6.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant7.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant8.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant9.svg"></b-carousel-slide>
            <b-carousel-slide style="outline: none;" img-src="../img/variants/v2/variant10.svg"></b-carousel-slide>
        </b-carousel>

        <b-modal id="modal" centered header-bg-variant="info" ref="variantModal" :title="$t('deviceEdit.changeVariant')" @ok="variantUpdate()"
                 @cancel="variantUpdateCancel()" no-close-on-backdrop no-close-on-esc hide-header-close :cancel-title="$t('general.cancel')">
            <span>
                {{ $t('deviceEdit.changeVariantDescPart1') }} <br>
                {{ $t('deviceEdit.changeVariantDescPart2') }}
            </span>
        </b-modal>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import vueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import {isMobile} from "@/utilities/mobile.navigation.utility";
    import {getSliderDotValue} from "@/utilities/parameter.utility";

    export default {
        name: "VariantSlider",

        components: {
            vueSlider
        },

        props: [
            'parameterKey',
            'value',
            'min',
            'max',
            'pending'
        ],

        data() {
            return {
                parameterValue: this.value,
                valueWatcher: null,
                draggingSlider: false,
                sliderProcessStyle: {backgroundColor: 'var(--primary)'}
            }
        },

        computed: {
            ...mapGetters({
                isDemo: 'user/isDemo',
                getParameters: 'virtualDevice/parameters',
                getMacAddress: 'virtualDevice/macAddress',
                getUnlinked: 'virtualDevice/unlinked'
            }),

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            },

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            isMobileView() {
                return isMobile();
            },

            variantSlide: {
                get: function () {
                    return this.parameterValue - 1
                },

                set: function (newValue) {
                    //added to bypass vue warning
                }
            }
        },

        created() {
            this.valueWatcher = this.$watch('parameters.p_1.value', function (newVal, oldVal) { //watch changes transmitted from physical device
                this.parameterValue = newVal;
            });
        },

        beforeDestroy() {
            this.valueWatcher();
        },

        methods: {
            dragStart() {
                this.draggingSlider = true;
                this.beforeVariantDragValue = this.parameterValue;
            },

            dragEnd() {
                if (this.beforeVariantDragValue.toString() !== this.parameterValue.toString()) {
                    if (!this.isDemo && !this.isUnlinked && !this.isEmpty) {
                        this.$refs.variantModal.show();
                    } else {
                        this.variantUpdateCancel();
                    }
                }
            },

            variantValueChange() {
                if (!this.draggingSlider) {
                    this.draggingSlider = true;
                    this.$refs.variantModal.show();
                }
            },

            variantUpdateCancel() {
                this.draggingSlider = false;
                this.parameterValue = this.beforeVariantDragValue;
            },

            variantUpdate() {
                this.$emit('update:value', this.parameterValue);
            },

            getLabelFormattedValue(val) {
                return getSliderDotValue(this.parameterKey, val, this.min, this.max, this.parameters);
            }
        }
    }
</script>

<style scoped>

</style>