<template>
    <b-row class="my-4" align-h="center">
        <b-col cols="auto">
            <b-button-group size="lg">
                <b-button class="shadow-none" id="auto-button" variant="primary" :pressed="operation == OPERATION.AUTO" @click="operationPressed">
                    AUTO
                </b-button>
                <b-button class="shadow-none" id="forced-button" variant="primary" :pressed="operation == OPERATION.FORCED" @click="operationPressed">
                    FORCED
                </b-button>
            </b-button-group>
        </b-col>
    </b-row>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "AutoForcedButton",

        props: [
            'value'
        ],

        data() {
            return {}
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
            }),

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            operation: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value);
                }
            },

            OPERATION() {
                return {
                    AUTO: 0,
                    FORCED: 1
                }
            }
        },

        methods: {
            operationPressed(event) {
                let buttonId = event.target.id;

                if (buttonId === 'auto-button') {
                    this.operation = this.OPERATION.AUTO;
                } else if (buttonId === 'forced-button') {
                    this.operation = this.OPERATION.FORCED;
                }
            }
        }
    }
</script>

<style scoped>
</style>