<template>
    <b-form-radio-group :id="'input_' + parameterKey" :name="parameterKey" v-model="parameterValue" v-on:change="$emit('change', $event)">
        <b-form-radio v-for="(entry, index) in radioOptions" :value="typeof entry === 'string'? index.toString() : entry.index">
            {{ typeof entry === 'string'? entry : entry.name }}
        </b-form-radio>
    </b-form-radio-group>
</template>

<script>
    export default {
        name: "RadioButtonGroup",

        model: {
            prop: 'parameterValue',
            event: 'change'
        },

        props: {
            parameterKey: String,
            parameterValue: [Number, String],
            radioOptions: {
                type: Array,
                default: function () {
                    return ['0', '1', '2'];
                }
            }
        }
    }
</script>

<style scoped>

</style>