<template>
    <b-form-select :id="'input_' + parameterKey" size="sm" v-model="parameterValue" :options="timezoneOptions"></b-form-select>
</template>

<script>
    import {timezoneOptions} from "@/utilities/parameter.utility";

    export default {
        name: "Timezone",

        props: [
            'parameterKey',
            'value'
        ],

        data() {
            return {
                timezoneOptions: timezoneOptions
            }
        },

        computed: {
            parameterValue: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value);
                }
            },
        }
    }
</script>

<style scoped>

</style>