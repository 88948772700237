<template>
    <off-on-schedule-button v-if="type === 'offOnSchedule'" v-bind:value.sync="parameterValue"/>

    <auto-forced-button v-else-if="type === 'autoForced'" v-bind:value.sync="parameterValue"/>

    <auto-close-right-left v-else-if="type === 'autoLeftRightClose'" v-bind:value.sync="parameterValue"> </auto-close-right-left>

    <heat-cool v-else-if="type === 'mode'" v-bind:value.sync="parameterValue"/>

    <thermostat-slider v-else-if="type === 'setpoint' && fahrenheitModeActive && isFahrenheitParameter" :module-number="moduleNumber"
                       v-bind:value.sync="parameterValue" :min="fmin" :max="fmax" :unit="'fahrenheit'"/>

    <thermostat-slider v-else-if="type === 'setpoint'" :module-number="moduleNumber" v-bind:value.sync="parameterValue" :min="min" :max="max"
                       :unit="'celsius'"/>
</template>

<script>
    import {mapGetters} from 'vuex';
    import OffOnScheduleButton from "./OffOnScheduleButton";
    import AutoForcedButton from "./AutoForcedButton";
    import HeatCool from "./HeatCool";
    import ThermostatSlider from "./ThermostatSlider";
    import AutoCloseRightLeft from "./AutoCloseRightLeft";

    export default {
        name: "InfoParameter",

        components: {
            AutoCloseRightLeft,
            OffOnScheduleButton,
            AutoForcedButton,
            HeatCool,
            ThermostatSlider
        },

        props: [
            'moduleNumber',
            'parameterKey',
            'value',
            'type',
            'min',
            'max',
            'fmin',
            'fmax'
        ],

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            parameterValue: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value)
                }
            },

            fahrenheitModeActive() {
                return this.parameters.p_186.value == 1;
            },

            isFahrenheitParameter() {
                return this.fmin;
            }
        }
    }
</script>

<style scoped>
</style>