<template>
    <b-row class="pb-2">
        <parameter-tooltip :parameter-key="paramKey" :parameter-description="description "/>

        <!--Parameter Name-->
        <b-col id="parameter-name" style="padding-top: 0.2rem;" cols="7" sm="3" md="3" lg="3" xl="3">
            <label>{{ name }}</label>
        </b-col>
        <!------------------>

        <!--Parameter Input-->
        <b-col v-if="isReadOnly(paramKey)" class="pl-3 pb-4 readonly-alignment" cols="1" sm="2" md="2" lg="2" xl="1">
            <read-only :parameter-key="paramKey" :parameter-value="parameterValue"/>
        </b-col>

        <b-col v-else-if="type === 'offDailyWeekly'" class="pl-3 pb-4 radio-button-alignment" cols="10" sm="6" md="6" lg="6" xl="6">
            <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                :radio-options="['Off', $t('deviceEdit.daily'), $t('deviceEdit.weekly')]"/>
        </b-col>

        <b-col v-else-if="type === 'boolean'" class="pl-3 pb-4 radio-button-alignment" cols="6" sm="4" md="3" lg="3" xl="2">
            <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['Off', 'On']"/>
        </b-col>

        <b-col v-else-if="type === 'timezone'" class="pl-3 py-2 py-sm-1" cols="6" sm="4" md="3" lg="3" xl="2">
            <timezone :parameter-key="paramKey" v-bind:value.sync="parameterValue"/>
        </b-col>

        <b-col v-else class="pl-3 pb-4" cols="10" sm="7" md="7" lg="7" xl="5">
            <div v-if="paramKey === 'p_1'" style="padding-top: 0.2rem;">
                <variant-slider :parameter-key="paramKey" v-bind:value.sync="parameterValue" :min="min" :max="max" :pending="pending"/>
            </div>

            <div v-else-if="type === 'slider'" style="padding-top: 0.2rem;">
                <slider v-if="fahrenheitModeActive && isFahrenheitParameter" :parameter-key="paramKey" v-bind:value.sync="parameterValue" :min="fmin"
                        :max="fmax"/>
                <slider v-else :parameter-key="paramKey" v-bind:value.sync="parameterValue" :min="min" :max="max"/>
            </div>

            <div v-else-if="type === 'delaySlider'" style="padding-top: 0.2rem;">
                <slider :parameter-key="paramKey" v-bind:value.sync="parameterValue" :min="min" :max="max" :interval="5"/>
            </div>

            <div v-else-if="type === 'setpoint'" class="readonly-alignment">
                <slider v-if="shouldRenderSetpointSlider() && fahrenheitModeActive && isFahrenheitParameter" :parameter-key="paramKey"
                        v-bind:value.sync="parameterValue" :min="fmin" :max="fmax"/>

                <slider v-else-if="shouldRenderSetpointSlider()" :parameter-key="paramKey" v-bind:value.sync="parameterValue" :min="min" :max="max"/>

                <strong v-else>{{ setpointValue }}</strong>
            </div>

            <div v-else-if="type === 'offOnSchedule'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['Off', 'On', $t('deviceEdit.schedule')]"/>
            </div>

            <div v-else-if="type === 'offOn'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['Off', 'On']"/>
            </div>

            <div v-else-if="type === 'loadType'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                    :radio-options="[$t('deviceEdit.alternate'), $t('deviceEdit.absolute')]"/>
            </div>

            <div v-else-if="type === 'autoForced'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['Auto', $t('deviceEdit.forced')]"/>
            </div>

            <div v-else-if="type === 'autoLeftRightClose'" class="radio-button-alignment">
              <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="apertureOptions"/>
            </div>

            <div v-else-if="type === 'relayPump'" class="radio-button-alignment">
                <b-form-radio-group :id="'input_' + paramKey" :name="paramKey" v-model="parameterValue">
                    <b-form-radio :value="0">Standard</b-form-radio>
                    <b-form-radio :value="1" :disabled="!freeAnalogueControllerExists">V1</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-else-if="type === 'pumpType'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['PWM', '0-10V']"/>
            </div>

            <div v-else-if="type === 'pumpSignal'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                    :radio-options="[$t('deviceEdit.inverted'), $t('deviceEdit.normal')]"/>
            </div>

            <div v-else-if="type === 'moduleType'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                    :radio-options="['Disabled', $t('deviceEdit.thermostat'), $t('deviceEdit.recycle'), 'On/Off']"/>
            </div>

            <div v-else-if="type === 'moduleTypePleion'">
                <b-form-select :parameter-key="paramKey" v-model="parameterValue" :options="pleionModuleOptions"/>
            </div>

            <div v-else-if="type === 'cp'">
                <b-form-select :parameter-key="paramKey" v-model="parameterValue" :options="cpOptions"/>
            </div>

            <div v-else-if="type === 'hourSelect'">
                <b-form-select :parameter-key="paramKey" v-model="parameterValue" :options="getHourSelectOptions(min, max)"/>
            </div>

            <b-col v-else-if="type === 'yesNoOff'" class="radio-button-alignment pl-0">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="[$t('deviceEdit.si'), 'No', 'Off']"/>
            </b-col>

          <div v-else-if="type === 'orientation'">
            <b-form-select style="width: 50%" :parameter-key="paramKey" v-model="parameterValue" :options="orientationOptions"/>
          </div>

          <div v-else-if="type === 'minutesInHours'">
          <TimePicker :minute-interval="15" hide-clear-button lazy v-model="timeValue"></TimePicker>
        </div>

            <div v-else-if="type === 'storageSensor'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['S1', 'S2', 'S3', 'S4']"/>
            </div>

            <div v-else-if="type === 'moduleSensor'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['S1', 'S2', 'S3', 'S4']"/>
            </div>

            <div v-else-if="type === 'sourceSensor'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['S1', 'S2', 'S3', 'S4']"/>
            </div>

            <div v-else-if="type === 'refRelay'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['R1', 'R2', 'R3']"/>
            </div>

            <div v-else-if="type === 'moduleRelay'"/>

            <div v-else-if="type === 'mode'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                    :radio-options="[$t('deviceEdit.heat'), $t('deviceEdit.cool')]"/>
            </div>

            <div v-else-if="type === 'lowHigh'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                    :radio-options="[{index: '1', name: '1'}, {index: '2', name: '2'}]"/>
            </div>

            <div v-else-if="type === 'storageThreeLevels'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue"
                                    :radio-options="[{index: '1', name: '1'}, {index: '2', name: '2'}, {index: '3', name: '3'}]"/>
            </div>

            <div v-else-if="type === 'sensorType'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['PT1000', 'NTC 10K']"/>
            </div>

            <div v-else-if="type === 'betaCoefficient'">
                <beta-coefficient v-bind:value.sync="parameterValue" :min="min" :max="max"/>
            </div>

            <div v-else-if="type === 'sensorUnits'" class="radio-button-alignment">
                <radio-button-group :parameter-key="paramKey" v-model="parameterValue" :radio-options="['°C', '°F']"/>
            </div>

            <div v-else-if="!type">
                <input type="number" autocomplete="off" class="form-control input-sm" :id="'input_' + paramKey" :name="paramKey"
                       v-model="parameterValue">
            </div>
        </b-col>
        <!------------------->

        <b-col class="py-2 py-sm-1" cols="1" sm="1" md="1" lg="1" xl="1" :id="'pending_' + paramKey" v-show="pending || showWarning">
            <parameter-spinner :parameter-key="paramKey" :show-warning="showWarning"/>
        </b-col>
    </b-row>
</template>

<script>

    import {mapGetters} from 'vuex';
    import ParameterTooltip from "./ParameterTooltip";
    import ParameterSpinner from "./ParameterSpinner";
    import ReadOnly from "./ReadOnly";
    import RadioButtonGroup from "./RadioButtonGroup";
    import BetaCoefficient from "./BetaCoefficient";
    import Timezone from "./Timezone";
    import Slider from "./Slider";
    import VariantSlider from "./VariantSlider";
    import {getSetpointValue, isReadOnlyV2} from "@/utilities/parameter.utility";
    import TimePicker from 'vue2-timepicker'
    import {convertHrsMinstToMins, convertMinsToHrsMins} from "../../../../../../utilities/parameter.utility";

    export default {
        name: "EditParameter",

        components: {
            Timezone,
            Slider,
            VariantSlider,
            ParameterTooltip,
            ParameterSpinner,
            ReadOnly,
            RadioButtonGroup,
            BetaCoefficient,
            TimePicker
        },

        props: {
            paramKey: String,
            value: [Number, String, Object],
            name: String,
            type: String,
            description: String,
            min: Number,
            max: Number,
            fmin: Number,
            fmax: Number,
            pending: Boolean,
            showWarning: Boolean,
            freeAnalogueControllerExists: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                pleionModuleOptions: [
                    {value: 0, text: this.$t('deviceEdit.disabled')}, {value: 1, text: this.$t('deviceEdit.thermostat')},
                    {value: 2, text: this.$t('deviceEdit.recycle')}, {value: 3, text: 'On/Off'}, {value: 4, text: 'Eclipse'},
                    {value: 5, text: this.$t('deviceEdit.fireplace')}, {value: 6, text: this.$t('deviceEdit.heatExchange')},
                    {value: 7, text: this.$t('deviceEdit.parallelRelay')}, {value: 8, text: this.$t('deviceEdit.flowSwitch')}
                ],
              orientationOptions: [
                    {value: 0, text: 'ovest'},
                    {value: 1, text: 'est'},
                    {value: 2, text: 'sud'},
                    {value: 3, text: 'nord'},
                ],
                cpOptions: [{value: 0, text: 'WATER'}, {value: 1, text: 'FAST G28'}, {value: 2, text: 'FAST GHP32'}, {value: 3, text: 'PREMIX LS28'},
                    {value: 4, text: 'PREMIX LS32'}, {value: 5, text: 'THSOL 28'}, {value: 6, text: 'WATER PG30%'}, {value: 7, text: 'WATER PG35%'},
                    {value: 8, text: 'WATER PG40%'}, {value: 9, text: 'WATER PG45%'}, {value: 10, text: 'WATER PG50%'},
                    {value: 11, text: 'WATER EG30%'}, {value: 12, text: 'WATER EG35%'}, {value: 13, text: 'WATER EG40%'},
                    {value: 14, text: 'WATER EG45%'}, {value: 15, text: 'WATER EG50%'}
                ]
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

          apertureOptions: function () {
            let val = parseInt(this.parameters?.p_278?.value);
            switch (val) {
              case 0:
                return ['Auto', 'Chiudi', 'Aperto a sud', 'Aperto al nord'];
                break;
              case 1:
                return ['Auto', 'Chiudi', 'Aperto al nord', 'Aperto a sud'];
                break;
              case 2:
                return ['Auto', 'Chiudi', 'Aperto ad est', 'Aperto a ovest'];
                break;
              case 3:
                return ['Auto', 'Chiudi', 'Aperto a ovest', 'Aperto ad est'];
                break;
              default:
                return ['Auto', 'Chiudi', 'Apri Sinistra', 'Apri Destra'];
                break;
            }
          },

            setpointValue() {
                return getSetpointValue(this.paramKey, this.parameters);
            },

            timeValue: {
              get: function () {
                return convertMinsToHrsMins(this.paramKey, this.parameters);
              },

              set: function (value) {
                this.$emit('update:value', convertHrsMinstToMins(value))
              }

            },

            parameterValue: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value)
                }
            },

            fahrenheitModeActive() {
                return this.parameters.p_186.value == 1;
            },

            isFahrenheitParameter() {
                return this.fmin;
            }
        },

        methods: {
            isReadOnly(key) {
                return isReadOnlyV2(key);
            },

            shouldRenderSetpointSlider() {
                if (this.paramKey === 'p_35') return parseInt(this.parameters.p_101.value) !== 2;
                else if (this.paramKey === 'p_87') return parseInt(this.parameters.p_105.value) !== 2;
                else if (this.paramKey === 'p_94') return parseInt(this.parameters.p_109.value) !== 2;
                else if (this.paramKey === 'p_39') return parseInt(this.parameters.p_103.value) !== 2;
                else if (this.paramKey === 'p_91') return parseInt(this.parameters.p_107.value) !== 2;
                else if (this.paramKey === 'p_98') return parseInt(this.parameters.p_111.value) !== 2;
            },

            getHourSelectOptions(min, max) {
                let hourOptions = [{value: 0, text: '00:00'}, {value: 1, text: '01:00'}, {value: 2, text: '02:00'}, {value: 3, text: '03:00'},
                    {value: 4, text: '04:00'}, {value: 5, text: '05:00'}, {value: 6, text: '06:00'}, {value: 7, text: '07:00'},
                    {value: 8, text: '08:00'}, {value: 9, text: '09:00'}, {value: 10, text: '10:00'}, {value: 11, text: '11:00'},
                    {value: 12, text: '12:00'}, {value: 13, text: '13:00'}, {value: 14, text: '14:00'}, {value: 15, text: '15:00'},
                    {value: 16, text: '16:00'}, {value: 17, text: '17:00'}, {value: 18, text: '18:00'}, {value: 19, text: '19:00'},
                    {value: 20, text: '20:00'}, {value: 21, text: '21:00'}, {value: 22, text: '22:00'}, {value: 23, text: '23:00'}
                ];

                return hourOptions.slice(min, max + 1);
            }
        }
    }

</script>

<style scoped>
    .radio-button-alignment {
        padding-top: 0.3rem;
    }

    .readonly-alignment {
        padding-top: 0.3rem;
    }
</style>















