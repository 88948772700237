<template>
    <b-form-input id="betaCoefficient" type="number" :min="min" :max="max" v-model="betaCoefficient" no-wheel @click="$root.$emit('hideBottomNav')"/>
</template>

<script>
    import {debounce} from 'lodash';

    export default {
        name: "BetaCoefficient",

        props: [
            'value',
            'min',
            'max'
        ],

        data() {
            return {
                debounceBetaCoefficientUpdate: null
            }
        },

        computed: {
            betaCoefficient: {
                get() {
                    return this.value;
                },

                set(value) {
                    this.debounceBetaCoefficientUpdate(value);
                }
            }
        },

        created() {
            this.debounceBetaCoefficientUpdate = debounce((value) => {
                if (value >= this.min && value <= this.max) {
                    this.$emit('update:value', value);
                }
            }, 500);
        }
    }
</script>

<style scoped>

</style>