<template>
    <edit-parameter v-if="mode === 'edit'" :param-key="paramKey" v-bind:value.sync="value" :name="paramValue.name" :type="paramValue.type"
                    :min="paramValue.min" :max="paramValue.max" :fmin="paramValue.fmin" :fmax="paramValue.fmax" :description="paramValue.description"
                    :pending="pending" :show-warning="showWarning" :free-analogue-controller-exists="freeAnalogueControllerExists"></edit-parameter>

    <info-parameter v-else-if="mode === 'info'" :module-number="moduleNumber" :parameter-key="paramKey" v-bind:value.sync="value"
                    :type="paramValue.type" :min="paramValue.min" :max="paramValue.max" :fmin="paramValue.fmin"
                    :fmax="paramValue.fmax"></info-parameter>
</template>

<script>
    import {mapGetters} from 'vuex';
    import EditParameter from "./edit/EditParameter";
    import InfoParameter from "./info/InfoParameter";

    export default {
        name: "Parameter",

        components: {
            InfoParameter,
            EditParameter
        },

        props: {
            moduleNumber: {
                type: Number,
                default: 0
            },
            paramKey: String,
            paramValue: Object,
            mode: {
                type: String,
                default: 'edit'
            },
            freeAnalogueControllerExists: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                value: this.paramValue.value,
                valueWatcher: null,
                pending: false,
                physicalDeviceUpdate: false,
                retryInterval: null,
                numberOfRetries: 0,
                showWarning: false
            }
        },

        computed: {
            ...mapGetters({
                isDemo: 'user/isDemo',
                getParameters: 'virtualDevice/parameters',
                getMacAddress: 'virtualDevice/macAddress',
                getUnlinked: 'virtualDevice/unlinked'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            }
        },

        watch: {
            value: function () {
                if (!this.physicalDeviceUpdate) {
                    if (!this.isDemo && !this.isUnlinked && !this.isEmpty) {
                        this.updateParameter();
                    } else {
                        this.$nextTick(() => {
                            this.physicalDeviceUpdate = true; //because we do not want to trigger the value watcher when changing the value below
                            this.$set(this, 'value', this.parameters[this.paramKey].value.toString());
                        })
                    }
                } else {
                    this.physicalDeviceUpdate = false;
                }
            }
        },

        created() {
            this.valueWatcher = this.$watch('parameters.' + this.paramKey + '.value', function () { //watch changes transmitted from physical device
                if (!this.pending && parseInt(this.value) !== parseInt(this.parameters[this.paramKey].value)) {
                    this.value = this.parameters[this.paramKey].value;
                    this.physicalDeviceUpdate = true;
                }
            });
        },

        beforeDestroy() {
            clearInterval(this.retryInterval);
            this.valueWatcher();
        },

        methods: {
            updateParameter() {
                clearInterval(this.retryInterval);

                let parameter = {};
                parameter['id'] = this.$route.params.id;
                parameter[this.paramKey] = this.value;
                this.sendUpdateRequest(parameter);
                this.showWarning = false;
                this.pending = true;

                this.retryInterval = setInterval(() => {
                    this.numberOfRetries += 1;
                    if (this.numberOfRetries <= 5) {
                        this.sendUpdateRequest(parameter);
                    } else {
                        this.numberOfRetries = 0;
                        this.showWarning = true;
                        this.pending = false;
                        clearInterval(this.retryInterval);
                        this.physicalDeviceUpdate = true; //because we do not want to trigger the value watcher when changing the value below
                        this.value = this.parameters[this.paramKey].value.toString();
                    }
                }, 5000, parameter)
            },

            sendUpdateRequest(parameter) {
                this.$axios.post('/update-parameter-v2', {
                    data: parameter
                }).then((response) => {
                    let data = response.data;

                    if (parseInt(data[this.paramKey]) === parseInt(this.value)) {
                        this.pending = false;
                        clearInterval(this.retryInterval);
                    }
                });
            }
        }
    }
</script>

<style scoped>
</style>















