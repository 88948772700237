<template>
    <b-row class="my-4" align-h="center">
        <b-col cols="auto">
          <b-button-group size="sm">
            <b-button class="shadow-none" id="auto-button" variant="primary" :pressed="operation == OPERATION.AUTO"
                      @click="operationPressed">
              AUTO
            </b-button>
            <b-button class="shadow-none" id="close-button" variant="primary" :pressed="operation == OPERATION.CLOSE"
                      @click="operationPressed">
              CHIUDI
            </b-button>
            <b-button class="shadow-none" id="right-button" variant="primary" :pressed="operation == OPERATION.RIGHT"
                      @click="operationPressed">
              {{rightString}}
            </b-button>
            <b-button class="shadow-none" id="left-button" variant="primary" :pressed="operation == OPERATION.LEFT"
                      @click="operationPressed">
              {{leftString}}
            </b-button>
            </b-button-group>
        </b-col>
    </b-row>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "AutoCloseRightLeft",

        props: [
            'value'
        ],

        data() {
            return {}
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
            }),

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            rightString(){
                return this.setStrings('right')
            },

            leftString(){
              return this.setStrings('left')
            },

            operation: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value);
                }
            },

            OPERATION() {
                return {
                    AUTO: 0,
                    CLOSE: 1,
                    RIGHT: 2,
                    LEFT: 3,

                }
            }
        },

        methods: {
            operationPressed(event) {
                let buttonId = event.target.id;

              if (buttonId === 'auto-button') {
                this.operation = this.OPERATION.AUTO;
              } else if (buttonId === 'close-button') {
                this.operation = this.OPERATION.CLOSE;
              } else if (buttonId === 'right-button') {
                this.operation = this.OPERATION.RIGHT;
              } else if (buttonId === 'left-button') {
                this.operation = this.OPERATION.LEFT;
              }
            },
          setStrings(position) {
            let val = parseInt(this.parameters?.p_278?.value);
            if (position == 'right') {
              if(val == 0) return 'SUD'
              if(val == 1) return 'NORD'
              if(val == 2) return 'EST'
              if(val == 3) return 'OVEST'
            } else if (position == 'left') {
              if(val == 0) return 'NORD'
              if(val == 1) return 'SUD'
              if(val == 2) return 'OVEST'
              if(val == 3) return 'EST'
            }
          },
        }
    }
</script>

<style scoped>
</style>