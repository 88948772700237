<template>
    <div>
        <spinner v-if="!showWarning" size="22" class="float-left" line-fg-color="var(--primary)"></spinner>

        <div id="warning" v-else>
            <b-btn :id="'warning_' + parameterKey" class="warning-button shadow-none">
                <i class="fa fa-warning warning-icon"></i>
            </b-btn>
            <b-tooltip :target="'warning_' + parameterKey" title="Communication error, check if device is online." placement="left" triggers="click">
            </b-tooltip>
        </div>
    </div>
</template>

<script>
    import Spinner from 'vue-simple-spinner';

    export default {
        name: "ParameterSpinner",

        components: {
            Spinner
        },

        props: [
            'showWarning',
            'parameterKey'
        ]
    }
</script>

<style scoped>
    .warning-button {
        color: white;
        background-color: white;
        border-color: white;
        padding: 0;
        margin: 0;
    }

    .warning-button:focus {
        background: white !important;
        border-color: white !important;
    }

    .warning-icon {
        color: orange;
        font-size: 22px;
        padding: 0;
    }
</style>