<template>
    <div>
        <div class="thermostat-mode"
             :style="{border: thermostatMode ? '2px solid lightskyblue' : '2px solid coral', background: thermostatMode ? 'lightskyblue' : 'coral'}"
             @click="changeThermostatMode">

            <b-img v-if="thermostatMode" src="/img/modules/cool.svg" width="30" height="30"/>
            <b-img v-else src="/img/modules/heat.svg" width="30" height="30"/>
        </div>

        <div style="position: absolute; left: 34%; bottom: -16%; font-size: 0.9rem;">
            {{ thermostatMode ? $t('deviceInfo.cool') : $t('deviceInfo.heat') }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeatCool",

        props: [
            'value'
        ],

        computed: {
            thermostatMode: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value);
                }
            }
        },

        methods: {
            changeThermostatMode() {
                this.thermostatMode = this.thermostatMode === 0 ? 1 : 0;
            }
        }
    }
</script>

<style scoped>
    .thermostat-mode {
        position: absolute;
        left: 33%;
        bottom: -3%;
        cursor: pointer;
        border-radius: 5px;
    }
</style>