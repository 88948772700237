<template>
    <div class="pl-3">
        <a :id="'tooltip_' + parameterKey" class="icon-info tooltip-anchor" tabindex="-1"></a>
        <b-tooltip :target="'tooltip_' + parameterKey" :title="parameterDescription" placement="right" triggers="hover"></b-tooltip>
    </div>
</template>

<script>
    export default {
        name: "ParameterTooltip",

        props: [
            'parameterKey',
            'parameterDescription'
        ]
    }
</script>

<style scoped>
    .tooltip-anchor {
        color: var(--primary);
        font-size: 1.05rem;
        padding-top: 0.34rem;
        display: inline-block;
        outline: none;
        text-decoration: none;
    }
</style>