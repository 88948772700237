<template>
    <div style='width: 200px; height: 200px;'>
        <ejs-circulargauge ref="circulargauge" :loaded="loaded" :enablePointerDrag='enablePointerDrag' :dragMove='dragMove' :dragEnd='dragEnd'
                           width='100%' height='200'>
            <e-axes>
                <e-axis :radius='gaugeradius' :startAngle='startAngle' :minimum='min' :maximum='max' :endAngle='endAngle' :majorTicks='majorTicks'
                        :lineStyle='lineStyle' :minorTicks='minorTicks' :labelStyle='labelStyle' :annotations='annotations' :ranges='ranges'>
                    <e-pointers>
                        <e-pointer type='RangeBar' :radius='rangeBarRadius' :color='rangeBarColor' :markerHeight='markerHeight'
                                   :markerWidth='markerWidth'></e-pointer>

                        <e-pointer :type='type' :radius='pointerRadius' :color='color' :markerShape='markerShape' :border="border"
                                   :markerHeight='markerHeight' :markerWidth='markerWidth'></e-pointer>
                    </e-pointers>
                </e-axis>
            </e-axes>
        </ejs-circulargauge>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {CircularGaugePlugin, getRangeColor, Annotations, Range} from "@syncfusion/ej2-vue-circulargauge";
    import {mapGetters} from 'vuex';
    import {getSetpointValue, getOperationParameterKey} from "@/utilities/parameter.utility";

    Vue.use(CircularGaugePlugin);

    export default Vue.extend({
        name: 'ThermostatSlider',

        provide: {
            circulargauge: [Annotations]
        },

        props: [
            'value',
            'moduleNumber',
            'min',
            'max',
            'unit'
        ],

        data() {
            return {
                enablePointerDrag: true,
                annotations: [{
                    content: '',
                    angle: 180, zIndex: '1',
                    radius: '0%'
                }],
                endAngle: 140,
                startAngle: 220,
                lineStyle: {width: 0, color: '#9E9E9E'},
                gaugeradius: '40%',
                majorTicks: {width: 0},
                minorTicks: {width: 0},
                labelStyle: {format: 'placeholder_for_empty'},
                ranges: [
                    {
                        start: -999, //set a big value so it gets min and max from axes
                        end: 999,
                        startWidth: 20,
                        endWidth: 20,
                        radius: '82rem',
                        color: 'lightgrey',
                    },
                ],
                type: 'Marker',
                // value: 70,
                markerShape: 'Circle',
                pointerRadius: '72.5rem',
                markerWidth: 30,
                markerHeight: 30,
                color: 'white',
                border: {
                    width: 4,
                    color: '#cbc4c5'
                },
                rangeBarColor: '#007BFF',
                rangeBarRadius: '82em',
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
            }),

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            setpoint: {
                get: function () {
                    return this.value;
                },

                set: function (value) {
                    this.$emit('update:value', value);
                }
            },

            operation() {
                return this.parameters[getOperationParameterKey(this.moduleNumber, this.parameters)].value;
            },
        },

        watch: {
            operation: function (newValue, oldValue) {
                this.changeSliderState(newValue);
            }
        },

        methods: {
            loaded: function () {
                this.changeSliderState(this.operation);
            },

            changeSliderState(operationValue) {
                this.setSliderValue(this.setpoint);

                if (operationValue == 0) {
                    this.enablePointerDrag = false;
                    this.$refs.circulargauge.ej2Instances.axes[0].pointers[0].color = 'lightgrey';
                    this.$refs.circulargauge.ej2Instances.setAnnotationValue(0, 0, this.setpointFormatter('', this.operation));
                } else if (operationValue == 1) {
                    this.enablePointerDrag = true;
                    this.$refs.circulargauge.ej2Instances.axes[0].pointers[0].color = this.rangeBarColor;
                    this.$refs.circulargauge.ej2Instances.setAnnotationValue(0, 0, this.setpointFormatter(this.setpoint, this.operation));
                } else if (operationValue == 2) {
                    let setpointValue = getSetpointValue(this.getSetpointParameterKey(), this.parameters);

                    if (setpointValue === "Off") {
                        this.enablePointerDrag = false;
                        this.$refs.circulargauge.ej2Instances.axes[0].pointers[0].color = 'lightgrey';
                        this.$refs.circulargauge.ej2Instances.setAnnotationValue(0, 0, this.setpointFormatter('', this.operation));
                    } else {
                        this.enablePointerDrag = false;
                        this.$refs.circulargauge.ej2Instances.axes[0].pointers[0].color = this.rangeBarColor;
                        this.$refs.circulargauge.ej2Instances.setAnnotationValue(0, 0, this.setpointFormatter(this.setpoint, this.operation));
                    }
                }
            },

            getSetpointParameterKey() {
                if (this.moduleNumber == 1) {
                    return 'p_35';
                } else if (this.moduleNumber == 2) {
                    return 'p_87';
                } else if (this.moduleNumber == 3) {
                    return 'p_94';
                }
            },

            setpointFormatter(value, operation) {
                let offFormat = '<div><span style="font-size: 2.1rem;color:black; font-weight: lighter;">OFF</span></div>';

                if (operation == 0) {
                    return offFormat;
                } else if (operation == 1) {
                    return '<div><span style="font-size: 2.1rem;color:black; font-weight: lighter;">' + value + '</span>' +
                    '<span style="font-size: 1.3rem;color:black; font-weight: lighter; position: absolute;">' + (this.unit === 'celsius' ? "°C" : "°F") + '</span></div>';
                } else if (operation == 2) {
                    let setpointValue = getSetpointValue(this.getSetpointParameterKey(), this.parameters);

                    if (setpointValue === "Off") {
                        return offFormat;
                    } else {
                        setpointValue = setpointValue.slice(0, -2); //trim degree celsius/fahrenheit sign

                        return '<div><span style="font-size: 2.1rem;color:black; font-weight: lighter;">' + setpointValue + '</span>' +
                            '<span style="font-size: 1.3rem;color:black; font-weight: lighter; position: absolute;">' + (this.unit === 'celsius' ? "°C" : "°F") + '</span></div>';
                    }
                }
            },

            dragMove: function (args) {
                let value = Math.trunc(args.currentValue);
                this.setSliderValue(value);
                this.$refs.circulargauge.ej2Instances.setAnnotationValue(0, 0, this.setpointFormatter(value, this.operation));
            },

            dragEnd: function (args) {
                let value = Math.trunc(args.currentValue);
                this.setSliderValue(value);
                this.$refs.circulargauge.ej2Instances.setAnnotationValue(0, 0, this.setpointFormatter(value, this.operation));
                this.setpoint = value;
            },

            setSliderValue(value) {
                let circularGauge = this.$refs.circulargauge.ej2Instances;
                circularGauge.setPointerValue(0, 0, value);
                circularGauge.setPointerValue(0, 1, value);
            }
        }
    })
</script>

<style scoped>
</style>